exports.apiURL = {
  // baseURL: "https://api.travvolt.com",
  // baseURL: "https://tourfinderbackend-production.up.railway.app",

  baseURL: "https://back.theskytrails.com",
  //  baseURL: "http://localhost:8000",

  flightEmail: "shivam@theskytrails.com",
  phoneNo: "9999479696",
};



 
