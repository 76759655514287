export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";



export const VERIFY_NUM_AFTERSOCIALLOGIN_REQUEST = "VERIFY_NUM_AFTERSOCIALLOGIN_REQUEST";
export const VERIFY_NUM_AFTERSOCIALLOGIN_SUCCESS = "VERIFY_NUM_AFTERSOCIALLOGIN_SUCCESS";


export const UPDATE_IMG_REQUEST_MYPROFILE = "UPDATE_IMG_REQUEST_MYPROFILE";
export const UPDATE_IMG_SUCCESS_MYPROFILE = "UPDATE_IMG_SUCCESS_MYPROFILE";
export const UPDATE_IMG_FAILURE_MYPROFILE = "UPDATE_IMG_FAILURE_MYPROFILE";


export const EDIT_REQUEST_MYPROFILE = "EDIT_REQUEST_MYPROFILE";
export const EDIT_SUCCESS_MYPROFILE = "EDIT_SUCCESS_MYPROFILE";
export const EDIT_FAILURE_MYPROFILE = "EDIT_FAILURE_MYPROFILE";



export const LOGIN_REQUEST_SOCIAL = "LOGIN_REQUEST_SOCIAL";
export const LOGIN_SUCCESS_SOCIAL = "LOGIN_SUCCESS_SOCIAL";
export const LOGIN_FAILURE_SOCIAL = "LOGIN_FAILURE_SOCIAL";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const USER_DATA = "USER_DATA";
