exports.footerNavigationdata = [
  {
    headText: "Flight Routes Across India",
    type: "flight",

    list: [
      {
        id: 1,
        from: "Bangalore",
        destination: "Chennai",
        code: "BLR",
        title: "Bangalore to Chennai Flight",
        arrivalCode: "MAA",
        code1: "BLR-MAA",
        fromDetails: {
          _id: "BLR",
          name: "Bangalore",
          CityCode: "BLR",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "BLR",
          code: "Kempegowda International Airport",
          state: "State not provided",
        },
        to: {
          _id: "MAA",
          name: "Chennai",
          CityCode: "MAA",
          CountryCode: "IN ",
          code: "Chennai International Airport",
          AirportCode: "MAA",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 2,
        from: "Bangalore",
        destination: "Goa",
        code: "BLR",
        title: "Bangalore to Goa Flight",
        arrivalCode: "GOI",
        code1: "BLR-GOI",
        fromDetails: {
          _id: "BLR",
          name: "Bangalore",
          CityCode: "BLR",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "BLR",
          code: "Kempegowda International Airport",
          state: "State not provided",
        },
        to: {
          _id: "GOI",
          name: "Goa",
          CityCode: "GOI",
          CountryCode: "IN ",
          code: "Dabolim Airport",
          AirportCode: "GOI",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 3,
        from: "Bangalore",
        destination: "Jaipur",
        code: "BLR",
        title: "Bangalore to Jaipur Flight",
        arrivalCode: "JAI",
        code1: "BLR-JAI",
        fromDetails: {
          _id: "BLR",
          name: "Bangalore",
          CityCode: "BLR",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "BLR",
          code: "Kempegowda International Airport",
          state: "State not provided",
        },
        to: {
          _id: "JAI",
          name: "Jaipur",
          CityCode: "JAI",
          CountryCode: "IN ",
          code: "Jaipur International Airport",
          AirportCode: "JAI",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 4,
        from: "Chennai",
        destination: "Bangalore",
        code: "MAA",
        title: "Chennai to Bangalore Flight",
        arrivalCode: "BLR",
        code1: "MAA-BLR",
        fromDetails: {
          _id: "MAA",
          name: "Chennai",
          CityCode: "MAA",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "MAA",
          code: "Chennai International Airport",
          state: "State not provided",
        },
        to: {
          _id: "BLR",
          name: "Bangalore",
          CityCode: "BLR",
          CountryCode: "IN ",
          code: "Kempegowda International Airport",
          AirportCode: "BLR",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 5,
        from: "Chennai",
        destination: "Coimbatore",
        code: "MAA",
        title: "Chennai to Coimbatore Flight",
        arrivalCode: "CJB",
        code1: "MAA-CJB",
        fromDetails: {
          _id: "MAA",
          name: "Chennai",
          CityCode: "MAA",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "MAA",
          code: "Chennai International Airport",
          state: "State not provided",
        },
        to: {
          _id: "CJB",
          name: "Coimbatore",
          CityCode: "CJB",
          CountryCode: "IN ",
          code: "Coimbatore International Airport",
          AirportCode: "CJB",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 6,
        from: "Chennai",
        destination: "Madurai",
        code: "MAA",
        title: "Chennai to Madurai Flight",
        arrivalCode: "IXM",
        code1: "MAA-IXM",
        fromDetails: {
          _id: "MAA",
          name: "Chennai",
          CityCode: "MAA",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "MAA",
          code: "Chennai International Airport",
          state: "State not provided",
        },
        to: {
          _id: "IXM",
          name: "Madurai",
          CityCode: "IXM",
          CountryCode: "IN ",
          code: "Madurai Airport",
          AirportCode: "IXM",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 11,
        from: "Goa",
        destination: "Mumbai",
        code: "GOI",
        title: "Goa to Mumbai Flight",
        arrivalCode: "BOM",
        code1: "GOI-BOM",
        fromDetails: {
          _id: "GOI",
          name: "Goa",
          CityCode: "GOI",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "GOI",
          code: "Dabolim Airport",
          state: "State not provided",
        },
        to: {
          _id: "BOM",
          name: "Mumbai",
          CityCode: "BOM",
          CountryCode: "IN ",
          code: "Chhatrapati Shivaji International Airport",
          AirportCode: "BOM",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 12,
        from: "Hyderabad",
        destination: "Bangalore",
        code: "BPM",
        title: "Hyderabad to Bangalore Flight",
        arrivalCode: "BLR",
        code1: "BPM-BLR",
        fromDetails: {
          _id: "BPM",
          name: "Hyderabad",
          CityCode: "BPM",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "BPM",
          code: "Begumpet Airport",
          state: "State not provided",
        },
        to: {
          _id: "BLR",
          name: "Bangalore",
          CityCode: "BLR",
          CountryCode: "IN ",
          code: "Kempegowda International Airport",
          AirportCode: "BLR",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 14,
        from: "Kolkata",
        destination: "Mumbai",
        code: "CCU",
        title: "Kolkata to Mumbai Flight",
        arrivalCode: "BOM",
        code1: "CCU-BOM",
        fromDetails: {
          _id: "CCU",
          name: "Kolkata",
          CityCode: "CCU",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "CCU",
          code: "Netaji Subhash Chandra Bose International Airport",
          state: "State not provided",
        },
        to: {
          _id: "BOM",
          name: "Mumbai",
          CityCode: "BOM",
          CountryCode: "IN ",
          code: "Chhatrapati Shivaji International Airport",
          AirportCode: "BOM",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 15,
        from: "Mumbai",
        destination: "Nagpur",
        code: "BOM",
        title: "Mumbai to Nagpur Flight",
        arrivalCode: "NAG",
        code1: "BOM-NAG",
        fromDetails: {
          _id: "BOM",
          name: "Mumbai",
          CityCode: "BOM",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "BOM",
          code: "Chhatrapati Shivaji International Airport",
          state: "State not provided",
        },
        to: {
          _id: "NAG",
          name: "Nagpur",
          CityCode: "NAG",
          CountryCode: "IN ",
          code: "Dr. Babasaheb Ambedkar International Airport",
          AirportCode: "NAG",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 16,
        from: "Mumbai",
        destination: "Varanasi",
        code: "BOM",
        title: "Mumbai to Varanasi Flight",
        arrivalCode: "VNS",
        code1: "BOM-VNS",
        fromDetails: {
          _id: "BOM",
          name: "Mumbai",
          CityCode: "BOM",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "BOM",
          code: "Chhatrapati Shivaji International Airport",
          state: "State not provided",
        },
        to: {
          _id: "VNS",
          name: "Varanasi",
          CityCode: "VNS",
          CountryCode: "IN ",
          code: "Lal Bahadur Shastri International Airport",
          AirportCode: "VNS",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 18,
        from: "Patna",
        destination: "Kolkata",
        code: "PAT",
        title: "Patna to Kolkata Flight",
        arrivalCode: "CCU",
        code1: "PAT-CCU",
        fromDetails: {
          _id: "PAT",
          name: "Patna",
          CityCode: "PAT",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "PAT",
          code: "Patna Airport",
          state: "State not provided",
        },
        to: {
          _id: "CCU",
          name: "Kolkata",
          CityCode: "CCU",
          CountryCode: "IN ",
          code: "Netaji Subhash Chandra Bose International Airport",
          AirportCode: "CCU",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 19,
        from: "Pune",
        destination: "Goa",
        code: "PNQ",
        title: "Pune to Goa Flight",
        arrivalCode: "GOI",
        code1: "PNQ-GOI",
        fromDetails: {
          _id: "PNQ",
          name: "Pune",
          CityCode: "PNQ",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "PNQ",
          code: "Pune Airport",
          state: "State not provided",
        },
        to: {
          _id: "GOI",
          name: "Goa",
          CityCode: "GOI",
          CountryCode: "IN ",
          code: "Dabolim Airport",
          AirportCode: "GOI",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 20,
        from: "Pune",
        destination: "Nagpur",
        code: "PNQ",
        title: "Pune to Nagpur Flight",
        arrivalCode: "NAG",
        code1: "PNQ-NAG",
        fromDetails: {
          _id: "PNQ",
          name: "Pune",
          CityCode: "PNQ",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "PNQ",
          code: "Pune Airport",
          state: "State not provided",
        },
        to: {
          _id: "NAG",
          name: "Nagpur",
          CityCode: "NAG",
          CountryCode: "IN ",
          code: "Dr. Babasaheb Ambedkar International Airport",
          AirportCode: "NAG",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 21,
        from: "Ranchi",
        destination: "Bangalore",
        code: "IXR",
        title: "Ranchi to Bangalore Flight",
        arrivalCode: "BLR",
        code1: "IXR-BLR",
        fromDetails: {
          _id: "IXR",
          name: "Ranchi",
          CityCode: "IXR",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "IXR",
          code: "Birsa Munda Airport",
          state: "State not provided",
        },
        to: {
          _id: "BLR",
          name: "Bangalore",
          CityCode: "BLR",
          CountryCode: "IN ",
          code: "Kempegowda International Airport",
          AirportCode: "BLR",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 22,
        from: "Shimla",
        destination: "Chandigarh",
        code: "SLV",
        title: "Shimla to Chandigarh Flight",
        arrivalCode: "IXC",
        code1: "SLV-IXC",
        fromDetails: {
          _id: "SLV",
          name: "Shimla",
          CityCode: "SLV",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "SLV",
          code: "Simla Airport",
          state: "State not provided",
        },
        to: {
          _id: "IXC",
          name: "Chandigarh",
          CityCode: "IXC",
          CountryCode: "IN ",
          code: "Shaheed Bhagat Singh International Airport",
          AirportCode: "IXC",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 23,
        from: "Dehradun",
        destination: "Mumbai",
        code: "DED",
        title: "Dehradun to Mumbai",
        arrivalCode: "BOM",
        code1: "DED-BOM",
        fromDetails: {
          _id: "DED",
          name: "DehraDun",
          CityCode: "DED",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "DED",
          code: "Jolly Grant Airport",
          state: "State not provided",
        },
        to: {
          _id: "BOM",
          name: "Mumbai",
          CityCode: "BOM",
          CountryCode: "IN ",
          code: "Chhatrapati Shivaji International Airport",
          AirportCode: "BOM",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 26,
        from: "Bangalore",
        destination: "Imphal",
        code: "BLR",
        title: "Bangalore to Imphal Flight",
        arrivalCode: "IMF",
        code1: "BLR-IMF",
        fromDetails: {
          _id: "BLR",
          name: "Bangalore",
          CityCode: "BLR",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "BLR",
          code: "Kempegowda International Airport",
          state: "State not provided",
        },
        to: {
          _id: "IMF",
          name: "Imphal",
          CityCode: "IMF",
          CountryCode: "IN ",
          code: "Tulihal International Airport",
          AirportCode: "IMF",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 27,
        from: "Bhopal",
        destination: "Hyderabad",
        code: "BHO",
        title: "Bhopal to Hyderabad Flight",
        arrivalCode: "BPM",
        code1: "BHO-BPM",
        fromDetails: {
          _id: "BHO",
          name: "Bhopal",
          CityCode: "BHO",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "BHO",
          code: "Raja Bhoj Airport",
          state: "State not provided",
        },
        to: {
          _id: "BPM",
          name: "Hyderabad",
          CityCode: "BPM",
          CountryCode: "IN ",
          code: "Begumpet Airport",
          AirportCode: "BPM",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 28,
        from: "Bhubaneshwar",
        destination: "Chennai",
        code: "BBI",
        title: "Bhubaneshwar to Chennai Flight",
        arrivalCode: "MAA",
        code1: "BBI-MAA",
        fromDetails: {
          _id: "BBI",
          name: "Bhubaneshwar",
          CityCode: "BBI",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "BBI",
          code: "Biju Patnaik International Airport",
          state: "State not provided",
        },
        to: {
          _id: "MAA",
          name: "Chennai",
          CityCode: "MAA",
          CountryCode: "IN ",
          code: "Chennai International Airport",
          AirportCode: "MAA",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 29,
        from: "Chennai",
        destination: "Nagpur",
        code: "MAA",
        title: "Chennai to Nagpur Flight",
        arrivalCode: "NAG",
        code1: "MAA-NAG",
        fromDetails: {
          _id: "MAA",
          name: "Chennai",
          CityCode: "MAA",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "MAA",
          code: "Chennai International Airport",
          state: "State not provided",
        },
        to: {
          _id: "NAG",
          name: "Nagpur",
          CityCode: "NAG",
          CountryCode: "IN ",
          code: "Dr. Babasaheb Ambedkar International Airport",
          AirportCode: "NAG",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 30,
        from: "Chennai",
        destination: "Port Blair",
        code: "MAA",
        title: "Chennai to Port Blair Flight",
        arrivalCode: "IXZ",
        code1: "MAA-IXZ",
        fromDetails: {
          _id: "MAA",
          name: "Chennai",
          CityCode: "MAA",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "MAA",
          code: "Chennai International Airport",
          state: "State not provided",
        },
        to: {
          _id: "IXZ",
          name: "Port Blair",
          CityCode: "IXZ",
          CountryCode: "IN ",
          code: "Veer Savarkar International Airport",
          AirportCode: "IXZ",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 33,
        from: "Imphal",
        destination: "Guwahati",
        code: "IMF",
        title: "Imphal to Guwahati Flight",
        arrivalCode: "GAU",
        code1: "IMF-GAU",
        fromDetails: {
          _id: "IMF",
          name: "Imphal",
          CityCode: "IMF",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "IMF",
          code: "Tulihal International Airport",
          state: "State not provided",
        },
        to: {
          _id: "GAU",
          name: "Guwahati",
          CityCode: "GAU",
          CountryCode: "IN ",
          code: "Lokpriya Gopinath Bordoloi International",
          AirportCode: "GAU",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 34,
        from: "Imphal",
        destination: "Kolkata",
        code: "IMF",
        title: "Imphal to Kolkata Flight",
        arrivalCode: "CCU",
        code1: "IMF-CCU",
        fromDetails: {
          _id: "IMF",
          name: "Imphal",
          CityCode: "IMF",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "IMF",
          code: "Tulihal International Airport",
          state: "State not provided",
        },
        to: {
          _id: "CCU",
          name: "Kolkata",
          CityCode: "CCU",
          CountryCode: "IN ",
          code: "Netaji Subhash Chandra Bose International Airport",
          AirportCode: "CCU",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 35,
        from: "Indore",
        destination: "Raipur",
        code: "IDR",
        title: "Indore to Raipur Flight",
        arrivalCode: "RPR",
        code1: "IDR-RPR",
        fromDetails: {
          _id: "IDR",
          name: "Indore",
          CityCode: "IDR",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "IDR",
          code: "Devi Ahilya Bai Holkar International Airport",
          state: "State not provided",
        },
        to: {
          _id: "RPR",
          name: "Raipur",
          CityCode: "RPR",
          CountryCode: "IN ",
          code: "Swami Vivekananda Airport",
          AirportCode: "RPR",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 37,
        from: "Hyderabad",
        destination: "Nagpur",
        code: "BPM",
        title: "Hyderabad to Nagpur Flight",
        arrivalCode: "NAG",
        code1: "BPM-NAG",
        fromDetails: {
          _id: "BPM",
          name: "Hyderabad",
          CityCode: "BPM",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "BPM",
          code: "Begumpet Airport",
          state: "State not provided",
        },
        to: {
          _id: "NAG",
          name: "Nagpur",
          CityCode: "NAG",
          CountryCode: "IN ",
          code: "Dr. Babasaheb Ambedkar International Airport",
          AirportCode: "NAG",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 38,
        from: "Lucknow",
        destination: "Hyderabad",
        code: "LKO",
        title: "Lucknow to Hyderabad Flight",
        arrivalCode: "BPM",
        code1: "LKO-BPM",
        fromDetails: {
          _id: "LKO",
          name: "Lucknow",
          CityCode: "LKO",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "LKO",
          code: "Amausi",
          state: "State not provided",
        },
        to: {
          _id: "BPM",
          name: "Hyderabad",
          CityCode: "BPM",
          CountryCode: "IN ",
          code: "Begumpet Airport",
          AirportCode: "BPM",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 39,
        from: "Madurai",
        destination: "Hyderabad",
        code: "IXM",
        title: "Madurai to Hyderabad Flight",
        arrivalCode: "BPM",
        code1: "IXM-BPM",
        fromDetails: {
          _id: "IXM",
          name: "Madurai",
          CityCode: "IXM",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "IXM",
          code: "Madurai Airport",
          state: "State not provided",
        },
        to: {
          _id: "BPM",
          name: "Hyderabad",
          CityCode: "BPM",
          CountryCode: "IN ",
          code: "Begumpet Airport",
          AirportCode: "BPM",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 40,
        from: "Mumbai",
        destination: "Imphal",
        code: "BOM",
        title: "Mumbai to Imphal Flight",
        arrivalCode: "IMF",
        code1: "BOM-IMF",
        fromDetails: {
          _id: "BOM",
          name: "Mumbai",
          CityCode: "BOM",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "BOM",
          code: "Chhatrapati Shivaji International Airport",
          state: "State not provided",
        },
        to: {
          _id: "IMF",
          name: "Imphal",
          CityCode: "IMF",
          CountryCode: "IN ",
          code: "Tulihal International Airport",
          AirportCode: "IMF",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 41,
        from: "Mumbai",
        destination: "Srinagar",
        code: "BOM",
        title: "Mumbai to Srinagar Flight",
        arrivalCode: "SXR",
        code1: "BOM-SXR",
        fromDetails: {
          _id: "BOM",
          name: "Mumbai",
          CityCode: "BOM",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "BOM",
          code: "Chhatrapati Shivaji International Airport",
          state: "State not provided",
        },
        to: {
          _id: "SXR",
          name: "Srinagar",
          CityCode: "SXR",
          CountryCode: "IN ",
          code: "Srinagar International Airport",
          AirportCode: "SXR",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 42,
        from: "Pune",
        destination: "Ranchi",
        code: "PNQ",
        title: "Pune to Ranchi Flight",
        arrivalCode: "IXR",
        code1: "PNQ-IXR",
        fromDetails: {
          _id: "PNQ",
          name: "Pune",
          CityCode: "PNQ",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "PNQ",
          code: "Pune Airport",
          state: "State not provided",
        },
        to: {
          _id: "IXR",
          name: "Ranchi",
          CityCode: "IXR",
          CountryCode: "IN ",
          code: "Birsa Munda Airport",
          AirportCode: "IXR",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 43,
        from: "Raipur",
        destination: "Hyderabad",
        code: "RPR",
        title: "Raipur to Hyderabad Flight",
        arrivalCode: "BPM",
        code1: "RPR-BPM",
        fromDetails: {
          _id: "RPR",
          name: "Raipur",
          CityCode: "RPR",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "RPR",
          code: "Swami Vivekananda Airport",
          state: "State not provided",
        },
        to: {
          _id: "BPM",
          name: "Hyderabad",
          CityCode: "BPM",
          CountryCode: "IN ",
          code: "Begumpet Airport",
          AirportCode: "BPM",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 44,
        from: "Raipur",
        destination: "Mumbai",
        code: "RPR",
        title: "Raipur to Mumbai Flight",
        arrivalCode: "BOM",
        code1: "RPR-BOM",
        fromDetails: {
          _id: "RPR",
          name: "Raipur",
          CityCode: "RPR",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "RPR",
          code: "Swami Vivekananda Airport",
          state: "State not provided",
        },
        to: {
          _id: "BOM",
          name: "Mumbai",
          CityCode: "BOM",
          CountryCode: "IN ",
          code: "Chhatrapati Shivaji International Airport",
          AirportCode: "BOM",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
      {
        id: 46,
        from: "Kolkata",
        destination: "Aizawl",
        code: "CCU",
        title: "Kolkata to Aizawl Flight",
        arrivalCode: "AJL",
        code1: "CCU-AJL",
        fromDetails: {
          _id: "CCU",
          name: "Kolkata",
          CityCode: "CCU",
          CountryCode: "IN ",
          CountryName: "India",
          AirportCode: "CCU",
          code: "Netaji Subhash Chandra Bose International Airport",
          state: "State not provided",
        },
        to: {
          _id: "AJL",
          name: "Aizawl",
          CityCode: "AJL",
          CountryCode: "IN ",
          code: "Lengpui Airport",
          AirportCode: "AJL",
          createdAt: "Date not provided",
          updatedAt: "Date not provided",
          __v: 0,
        },
      },
    ],
  },
  {
    headText: "Top Hotels in India",
    type: "hotel",
    start: "Hotels in",

    list: [
      {
        rates: "concise",
        cityCode: 122579,
        currency: "INR",
        client_nationality: "IN",
        title: "Hotels in Ahmedabad",
        checkin: "2024-10-24",
        checkout: "2024-10-25",
        cutoff_time: 30000,
        version: "2.0",
      },
      {
        rooms: [
          {
            adults: 1,
            children_ages: [],
          },
        ],
        rates: "concise",
        cityCode: 122913,
        currency: "INR",
        client_nationality: "IN",
        checkin: "2024-12-24",
        checkout: "2024-12-25",
        cutoff_time: 30000,
        version: "2.0",
        title: "Hotels in Agra",
      },

      {
        cityName: "Bangalore",
        tboCityCode: 111124,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "DELHI",
        tbostateProvinceCode: "DL",
        grnCityCode: 121850,
        grnCountryName: "India",
        grnCountryCode: "IN",
        title: "Hotels in Bangalore",
        rooms: [
          {
            adults: 1,
            children_ages: [],
          },
        ],
      },
      {
        cityName: "Chennai",
        tboCityCode: 127343,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Tamil Nadu",
        tbostateProvinceCode: "TN",
        grnCityCode: 124623,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Chandigarh",
        tboCityCode: 114107,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Chandigarh",
        tbostateProvinceCode: "CH",
        grnCityCode: 122959,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Digha",
        tboCityCode: 115894,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "West Bengal",
        tbostateProvinceCode: "WB",
        grnCityCode: 165829,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Dehradun",
        tboCityCode: 116164,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Uttarakhand",
        tbostateProvinceCode: "UK",
        grnCityCode: 124826,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Darjeeling",
        tboCityCode: 116264,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "West Bengal",
        tbostateProvinceCode: "WB",
        grnCityCode: 123209,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Goa",
        tboCityCode: 119805,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Goa",
        tbostateProvinceCode: "GA",
        grnCityCode: 123318,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Gulmarg",
        tboCityCode: 119924,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Jammu and Kashmir",
        tbostateProvinceCode: "JK",
        grnCityCode: 128550,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },

      {
        cityName: "Haridwar",
        tboCityCode: 121186,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Uttarakhand",
        tbostateProvinceCode: "UK",
        grnCityCode: 125789,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Hyderabad",
        tboCityCode: 145710,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Andhra Pradesh",
        tbostateProvinceCode: "AP",
        grnCityCode: 122722,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Jaipur",
        tboCityCode: 122175,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Rajasthan",
        tbostateProvinceCode: "RJ",
        grnCityCode: 122730,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Jodhpur",
        tboCityCode: 145836,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Rajasthan",
        tbostateProvinceCode: "RJ",
        grnCityCode: 123257,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Lonavala",
        tboCityCode: 126630,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Maharashtra",
        tbostateProvinceCode: "MH",
        grnCityCode: 126659,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Lucknow",
        tboCityCode: 126666,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Uttar Pradesh",
        tbostateProvinceCode: "UP",
        grnCityCode: 122400,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },

      {
        cityName: "Madurai",
        tboCityCode: 127067,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Tamil Nadu",
        tbostateProvinceCode: "TN",
        grnCityCode: 124192,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Mahabaleshwar",
        tboCityCode: 125684,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Maharashtra",
        tbostateProvinceCode: "MH",
        grnCityCode: 121575,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Manali",
        tboCityCode: 126388,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Himachal Pradesh",
        tbostateProvinceCode: "HP",
        grnCityCode: 122766,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Matheran",
        tboCityCode: 146545,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Maharashtra",
        tbostateProvinceCode: "MH",
        grnCityCode: 128561,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Mussoorie",
        tboCityCode: 130341,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Uttarakhand",
        tbostateProvinceCode: "UK",
        grnCityCode: 121080,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Mumbai",
        tboCityCode: 144306,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Maharashtra",
        tbostateProvinceCode: "MH",
        grnCityCode: 103863,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Munnar",
        tboCityCode: 128573,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Kerala",
        tbostateProvinceCode: "KL",
        grnCityCode: 124834,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Nainital",
        tboCityCode: 129726,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Uttarakhand",
        tbostateProvinceCode: "UK",
        grnCityCode: 123559,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Ooty",
        tboCityCode: 130990,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Tamil Nadu",
        tbostateProvinceCode: "TN",
        grnCityCode: 124651,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Pondicherry",
        tboCityCode: 150358,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Tamil Nadu",
        tbostateProvinceCode: "TN",
        grnCityCode: 123601,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Pune",
        tboCityCode: 133133,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Maharashtra",
        tbostateProvinceCode: "MH",
        grnCityCode: 124649,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Rishikesh",
        tboCityCode: 134932,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Uttarakhand",
        tbostateProvinceCode: "UK",
        grnCityCode: 125097,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Shirdi",
        tboCityCode: 137316,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Maharashtra",
        tbostateProvinceCode: "MH",
        grnCityCode: 124840,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Srinagar",
        tboCityCode: 139456,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: " Uttarakhand",
        tbostateProvinceCode: "Uttarakhand",
        grnCityCode: 123036,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Shimla",
        tboCityCode: 138673,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Himachal Pradesh",
        tbostateProvinceCode: "HP",
        grnCityCode: 121267,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Tirupati",
        tboCityCode: 140311,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Andhra Pradesh",
        tbostateProvinceCode: "AP",
        grnCityCode: 125156,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Udaipur",
        tboCityCode: 140522,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Rajasthan",
        tbostateProvinceCode: "RJ",
        grnCityCode: 121588,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Ujjain",
        tboCityCode: 140701,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Madhya Pradesh",
        tbostateProvinceCode: "MP",
        grnCityCode: 104328,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Varanasi",
        tboCityCode: 141618,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Uttar Pradesh",
        tbostateProvinceCode: "UP",
        grnCityCode: 121451,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Vrindavan",
        tboCityCode: 141391,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Uttar Pradesh",
        tbostateProvinceCode: "UP",
        grnCityCode: 108362,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Mohali",
        tboCityCode: 106514,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Punjab",
        tbostateProvinceCode: "PB",
        grnCityCode: 150677,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Panchkula",
        tboCityCode: 131002,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Haryana",
        tbostateProvinceCode: "HR",
        grnCityCode: 150208,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "Jaisalmer",
        tboCityCode: 122326,
        tboCountryName: "India",
        tboCountryCode: "IN",
        tbostateProvince: "Rajasthan",
        tbostateProvinceCode: "RJ",
        grnCityCode: 122473,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
    ],
  },
  {
    headText: "Top International Hotel Destinations",
    type: "hotel",
    start: "Hotels in",

    list: [
      {
        cityName: "Abu Dhabi",
        tboCityCode: 100765,
        tboCountryName: "United Arab Emirates",
        tboCountryCode: "AE",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 123033,
        grnCountryName: "United Arab Emirates",
        grnCountryCode: "AE",
      },
      {
        cityName: "Amsterdam",
        tboCityCode: 109558,
        tboCountryName: "Netherlands",
        tboCountryCode: "NL",
        tbostateProvince: "New York",
        tbostateProvinceCode: "NY",
        grnCityCode: 123881,
        grnCountryName: "Netherlands",
        grnCountryCode: "NL",
      },

      {
        cityName: "Bali",
        tboCityCode: 110670,
        tboCountryName: "Indonesia",
        tboCountryCode: "ID",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 124781,

        grnCountryName: "Indonesia",
        grnCountryCode: "ID",
      },
      {
        cityName: "Bangkok",
        tboCityCode: 144092,
        tboCountryName: "Thailand",
        tboCountryCode: "TH",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 122861,
        grnCountryName: "Thailand",
        grnCountryCode: "TH",
      },
      {
        cityName: "Barcelona",
        tboCityCode: 110790,
        tboCountryName: "Spain",
        tboCountryCode: "ES",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 175100,
        grnCountryName: "Venezuela",
        grnCountryCode: "VE",
      },
      {
        cityName: "Budapest",
        tboCityCode: 113068,
        tboCountryName: "Hungary",
        tboCountryCode: "HU",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 123147,
        grnCountryName: "Hungary",
        grnCountryCode: "HU",
      },
      {
        cityName: "Colombo",
        tboCityCode: 144745,
        tboCountryName: "Sri Lanka",
        tboCountryCode: "LK",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 122500,
        grnCountryName: "Sri Lanka",
        grnCountryCode: "LK",
      },
      {
        cityName: "Dhaka",
        tboCityCode: 115879,
        tboCountryName: "Bangladesh",
        tboCountryCode: "BD",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 121096,
        grnCountryName: "Bangladesh",
        grnCountryCode: "BD",
      },
      {
        cityName: "Dubai",
        tboCityCode: 115936,
        tboCountryName: "United Arab Emirates",
        tboCountryCode: "AE",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 121449,
        grnCountryName: "United Arab Emirates",
        grnCountryCode: "AE",
      },
      {
        cityName: "Hanoi",
        tboCityCode: 145535,
        tboCountryName: "Vietnam",
        tboCountryCode: "VN",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 121399,
        grnCountryName: "Vietnam",
        grnCountryCode: "VN",
      },
      {
        cityName: "Istanbul",
        tboCityCode: 122727,
        tboCountryName: "Turkey",
        tboCountryCode: "TR",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 124161,
        grnCountryName: "Turkey",
        grnCountryCode: "TR",
      },
      {
        cityName: "Jakarta",
        tboCityCode: 122069,
        tboCountryName: "Indonesia",
        tboCountryCode: "ID",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 102002,
        grnCountryName: "Indonesia",
        grnCountryCode: "ID",
      },
      {
        cityName: "Kathmandu",
        tboCityCode: 145898,
        tboCountryName: "Nepal",
        tboCountryCode: "NP",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 121348,
        grnCountryName: "Nepal",
        grnCountryCode: "NP",
      },
      {
        cityName: "Koh Samui",
        tboCityCode: 124943,
        tboCountryName: "Thailand",
        tboCountryCode: "TH",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 124783,
        grnCountryName: "Thailand",
        grnCountryCode: "TH",
      },
      {
        cityName: "Krabi",
        tboCityCode: 124015,
        tboCountryName: "Thailand",
        tboCountryCode: "TH",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 124142,
        grnCountryName: "Thailand",
        grnCountryCode: "TH",
      },
      {
        cityName: "Kuala Lumpur",
        tboCityCode: 123768,
        tboCountryName: "Malaysia",
        tboCountryCode: "MY",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 124046,
        grnCountryName: "Malaysia",
        grnCountryCode: "MY",
      },
      {
        cityName: "Langkawi",
        tboCityCode: 100105,
        tboCountryName: "Malaysia",
        tboCountryCode: "MY",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 124669,
        grnCountryName: "Malaysia",
        grnCountryCode: "MY",
      },
      {
        cityName: "Las Vegas (NV)",
        tboCityCode: 125099,
        tboCountryName: "United States of America",
        tboCountryCode: "US",
        tbostateProvince: "Nevada",
        tbostateProvinceCode: "NV",
        grnCityCode: 124725,
        grnCountryName: "United States",
        grnCountryCode: "US",
      },
      {
        cityName: "London",
        tboCityCode: 126632,
        tboCountryName: "United Kingdom",
        tboCountryCode: "GB",
        tbostateProvince: "Ohio",
        tbostateProvinceCode: "OH",
        grnCityCode: 120829,
        grnCountryName: "United Kingdom",
        grnCountryCode: "GB",
      },
      {
        cityName: "Los Angeles",
        tboCityCode: 127285,
        tboCountryName: "Chile",
        tboCountryCode: "CL",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 125828,
        grnCountryName: "Chile",
        grnCountryCode: "CL",
      },
      {
        cityName: "Madrid",
        tboCityCode: 126260,
        tboCountryName: "Spain",
        tboCountryCode: "ES",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 121999,
        grnCountryName: "Spain",
        grnCountryCode: "ES",
      },
      {
        cityName: "Maldives",
        tboCityCode: 101365,
        tboCountryName: "Maldives",
        tboCountryCode: "MV",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 125048,
        grnCountryName: "Maldives",
        grnCountryCode: "MV",
      },
      {
        cityName: "Melbourne",
        tboCityCode: 127718,
        tboCountryName: "Australia",
        tboCountryCode: "AU",
        tbostateProvince: "Victoria",
        tbostateProvinceCode: "VIC",
        grnCityCode: 123077,
        grnCountryName: "Australia",
        grnCountryCode: "AU",
      },
      {
        cityName: "Milangram",
        tboCityCode: null,
        tboCountryName: null,
        tboCountryCode: null,
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 115323,
        grnCountryName: "India",
        grnCountryCode: "IN",
      },
      {
        cityName: "New York",
        tboCityCode: 130452,
        tboCountryName: "United States of America",
        tboCountryCode: "US",
        tbostateProvince: "New York",
        tbostateProvinceCode: "NY",
        grnCityCode: 120857,
        grnCountryName: "United States",
        grnCountryCode: "US",
      },
      {
        cityName: "Paris",
        tboCityCode: 131408,
        tboCountryName: "France",
        tboCountryCode: "FR",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 124269,
        grnCountryName: "France",
        grnCountryCode: "FR",
      },
      {
        cityName: "Pattaya",
        tboCityCode: 132068,
        tboCountryName: "Thailand",
        tboCountryCode: "TH",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 122688,
        grnCountryName: "Thailand",
        grnCountryCode: "TH",
      },
      {
        cityName: "Phi Phi Island / Ko Pee Pee",
        tboCityCode: 131076,
        tboCountryName: "Thailand",
        tboCountryCode: "TH",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: null,
        grnCountryName: null,
        grnCountryCode: null,
      },
      {
        cityName: "Phuket",
        tboCityCode: 131529,
        tboCountryName: "Thailand",
        tboCountryCode: "TH",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 124420,
        grnCountryName: "Thailand",
        grnCountryCode: "TH",
      },
      {
        cityName: "Rome",
        tboCityCode: 134622,
        tboCountryName: "Italy",
        tboCountryCode: "IT",
        tbostateProvince: "Georgia",
        tbostateProvinceCode: "GA",
        grnCityCode: 124595,
        grnCountryName: "Italy",
        grnCountryCode: "IT",
      },
      {
        cityName: "Seoul",
        tboCityCode: 137364,
        tboCountryName: "South Korea",
        tboCountryCode: "KR",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 124096,
        grnCountryName: "Korea, Republic of",
        grnCountryCode: "KR",
      },
      {
        cityName: "Singapore",
        tboCityCode: 138703,
        tboCountryName: "Singapore",
        tboCountryCode: "SG",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 121673,
        grnCountryName: "Singapore",
        grnCountryCode: "SG",
      },
      {
        cityName: "Sydney",
        tboCityCode: 140258,
        tboCountryName: "Australia",
        tboCountryCode: "AU",
        tbostateProvince: "Nova Scotia",
        tbostateProvinceCode: "NS",
        grnCityCode: 124002,
        grnCountryName: "Australia",
        grnCountryCode: "AU",
      },
      {
        cityName: "Toronto",
        tboCityCode: 140333,
        tboCountryName: "Canada",
        tboCountryCode: "CA",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 101001,
        grnCountryName: "Canada",
        grnCountryCode: "CA",
      },
      {
        cityName: "Vienna",
        tboCityCode: 140888,
        tboCountryName: "Austria",
        tboCountryCode: "AT",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 124588,
        grnCountryName: "Austria",
        grnCountryCode: "AT",
      },
      {
        cityName: "Zurich",
        tboCityCode: 142511,
        tboCountryName: "Switzerland",
        tboCountryCode: "CH",
        tbostateProvince: null,
        tbostateProvinceCode: null,
        grnCityCode: 124542,
        grnCountryName: "Switzerland",
        grnCountryCode: "CH",
      },
    ],
  },
  {
    headText: "Top Hotels and Resorts in India:",
    type: "hotel",
    start: "",

    list: [
      {
        _id: "6672a8b7a2ef65a7e05c3ec5",
        hotelCode: 1413095,
        hotelName: "Alila Diwa Goa",
        cityCode: 123318,
        countryCode: "IN",
        address:
          "48/10 Village Majorda, Adao Waddo, Salcette, Majorda Beach 403713, Goa",
        latitude: 15.30752157,
        longitude: 73.91069201,
        countryName: "India",
      },
      {
        _id: "6672a8a9a2ef65a7e05c10fe",
        hotelCode: 1406742,
        hotelName: "Anantya Resorts",
        cityCode: 118436,
        countryCode: "IN",
        address:
          "State Highway 45, Kaliel Village, Chittar Lake, Kanyakumari District, Tamil Nadu 629103",
        latitude: 8.3424493,
        longitude: 77.3717791,
        countryName: "India",
      },
      {
        _id: "6672a8b6a2ef65a7e05c3c5e",
        hotelCode: 1432779,
        hotelName: "Caravela Beach Resort Goa",
        cityCode: 123318,
        countryCode: "IN",
        address: "Varca Beach, Salcete, Goa 403721",
        latitude: 15.2104951,
        longitude: 73.93519878,
        countryName: "India",
      },
      {
        _id: "6672a8b3a2ef65a7e05c2e1c",
        hotelCode: 1403656,
        hotelName: "Fairmont Jaipur",
        cityCode: 122730,
        countryCode: "IN",
        address: "2, Riico Kukas, Jaipur 303101",
        latitude: 27.032459,
        longitude: 75.88933,
        countryName: "India",
      },
      {
        _id: "6672a8b6a2ef65a7e05c3bc9",
        hotelCode: 1398716,
        hotelName: "Grand Hyatt Goa",
        cityCode: 123318,
        countryCode: "IN",
        address: "P.O. Goa University, Bambolim 403206, Goa",
        latitude: 15.45519517,
        longitude: 73.84424508,
        countryName: "India",
      },
      {
        _id: "6672a8b6a2ef65a7e05c39aa",
        hotelCode: 1398722,
        hotelName: "Hard Rock Hotel Goa",
        cityCode: 123318,
        countryCode: "IN",
        address: "370/14 Porba Vaddo, Calangute 403516, Bardez, Goa",
        latitude: 15.54594439,
        longitude: 73.76652003,
        countryName: "India",
      },
      {
        _id: "6672a8b3a2ef65a7e05c2f52",
        hotelCode: 2163864,
        hotelName: "DoubleTree by Hilton Jaipur Amer",
        cityCode: 122730,
        countryCode: "IN",
        address: "935 Kukas Delhi Road, Jaipur 302028, Rajasthan",
        latitude: 27.04635837,
        longitude: 75.90510991,
        countryName: "India",
      },
      {
        _id: "6672a8b0a2ef65a7e05c25eb",
        hotelCode: 1377593,
        hotelName: "Hyatt Ahmedabad",
        cityCode: 122225,
        countryCode: "IN",
        address:
          "Plot 216, Town Plan Scheme 1, Near Vastrapur Lake, Vastrapur, Ahmedabad, Gujarat",
        latitude: 23.03926927,
        longitude: 72.53024503,
        countryName: "India",
      },
      {
        _id: "6672a8bea2ef65a7e05c5771",
        hotelCode: 1390101,
        hotelName: "Hyatt Regency Chennai",
        cityCode: 124623,
        countryCode: "IN",
        address: "365 Anna Salai, Teynampet, Chennai, Tamil Nadu",
        latitude: 13.043035,
        longitude: 80.24867,
        countryName: "India",
      },
      {
        _id: "6672a8bca2ef65a7e05c50d6",
        hotelCode: 1395033,
        hotelName: "Hyatt Regency Delhi",
        cityCode: 124054,
        countryCode: "IN",
        address: "Ring Road, Bhikaiji Cama Place, New Delhi 110607",
        latitude: 28.569035,
        longitude: 77.185234,
        countryName: "India",
      },
      {
        _id: "6672a8b9a2ef65a7e05c4263",
        hotelCode: 1391713,
        hotelName: "Le Meridien Kochi",
        cityCode: 123645,
        countryCode: "IN",
        address: "Maradu, Kochi 682304, Kerala",
        latitude: 9.933698082,
        longitude: 76.31644249,
        countryName: "India",
      },
      {
        _id: "6672a8c4a2ef65a7e05c68fe",
        hotelCode: 1418634,
        hotelName: "Neemrana Fort Palace",
        cityCode: 126652,
        countryCode: "IN",
        address:
          "122nd Milestone Delhi-Jaipur Highway, 15th Century, Neemrana 301705, Alwar, Rajasthan",
        latitude: 27.9939427,
        longitude: 76.38824813,
        countryName: "India",
      },
      {
        _id: "6672a8b2a2ef65a7e05c2cd1",
        hotelCode: 1402600,
        hotelName: "Novotel Hyderabad Convention Centre",
        cityCode: 122722,
        countryCode: "IN",
        address: "Near Hitec City, P.O Bag 1101, Kondapur, Hyderabad 500081",
        latitude: 17.472847,
        longitude: 78.372741,
        countryName: "India",
      },
      {
        _id: "6672a8ada2ef65a7e05c1a3f",
        hotelCode: 1431370,
        hotelName: "The Oberoi Udaivilas, Udaipur",
        cityCode: 121588,
        countryCode: "IN",
        address: "Haridasji Ki Magri, Mulla Talai, Udaipur 313001, Rajasthan",
        latitude: 24.577482,
        longitude: 73.67213,
        countryName: "India",
      },
      {
        _id: "6672a8c2a2ef65a7e05c60ff",
        hotelCode: 1430608,
        hotelName: "Poovar Island Resort",
        cityCode: 124838,
        countryCode: "IN",
        address: "K.p. Vii/911 Pozhiyoor, Trivandrum, 695513 Kerala",
        latitude: 8.307779,
        longitude: 77.082269,
        countryName: "India",
      },
      {
        _id: "6672a8c2a2ef65a7e05c60ff",
        hotelCode: 1430608,
        hotelName: "Poovar Island Resort",
        cityCode: 124838,
        countryCode: "IN",
        address: "K.p. Vii/911 Pozhiyoor, Trivandrum, 695513 Kerala",
        latitude: 8.307779,
        longitude: 77.082269,
        countryName: "India",
      },
      {
        _id: "6672a8b2a2ef65a7e05c2be6",
        hotelCode: 1402731,
        hotelName: "Pragati Resorts",
        cityCode: 122722,
        countryCode: "IN",
        address:
          "Chilukuru Balaji Temple Road, Proddutur Village, Shankarpally Mandal, Ranga Reddy District",
        latitude: 17.38963062,
        longitude: 78.18307757,
        countryName: "India",
      },
    ],
  },
];
